import Vue from 'vue';
import VueRouter from 'vue-router';

import vuetify from '@/plugins/vuetify';

import * as ROUTES from '@/constants/ROUTES';

import {
  layoutRoute, viewRoute, redirectRoute, requireAuthenticated, requireUnauthenticated,
} from '../utils/router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    } else if (to.name === from.name) {
      scrollTo = document.documentElement.scrollTop;
    }

    return vuetify.framework.goTo(scrollTo);
  },

  routes: [
    layoutRoute(ROUTES.ROUTE_SIGN_IN.path, 'base', [
      viewRoute({
        name: ROUTES.ROUTE_SIGN_IN.name,
        view: 'sign-in',
      }, requireUnauthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_HOME.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_HOME.name,
        view: 'home',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_ATENDIMENTO.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_ATENDIMENTO.name,
        view: 'atendimento',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_PRODUTO_FOOD.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_PRODUTO_FOOD.name,
        view: 'produto-food',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_PRODUTO.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_PRODUTO.name,
        view: 'produto',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_FOOD.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_FOOD.name,
        view: 'filtro-food',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_SCAN.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_SCAN.name,
        view: 'camera_barcode',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_VALOR.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_VALOR.name,
        view: 'valor-food',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_CONFIGURACOES.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_CONFIGURACOES.name,
        view: 'configuracoes',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    redirectRoute(ROUTES.ROUTE_HOME),
  ],
});

export default router;
